import React from 'react';

import Layout from '../../components/Layout';
import GlobalWrapper from '../../components/GlobalWrapper/GlobalWrapper';
import Hero from '../../components/Hero/Hero';
import Title from '../../components/Title/Title';
import Footnotes from '../../components/Footnotes/Footnotes';
import heroImg from '../../images/results/hero.png';
import { GtagHead } from '../../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Thank you for staying connected | Loqtorzi™ Patient website</title>
      <meta name='description'content='Thank you for staying connected'/>
    </>
  )
}

export default function ThankYou() {
  return (
    <Layout>
      <Hero image={ { src: heroImg, alt:'Hero thank you page' } }>
        <Title>
          <h1>Thank you for submitting your request</h1>
        </Title>
        <div className={'mt-[1.2rem] tablet:mt-[4rem]'}>
          Coherus BioSciences will get back to you shortly.
        </div>
      </Hero>

      <GlobalWrapper className={'mb-[2.5rem] tablet:mb-[6.7rem]'}>
        <Footnotes className={'mt-[1.9rem] tablet:mt-[4.5rem]'} items={[
          {
            bullet: '*',
            text: <span>NPC=recurrent locally advanced or metastatic nasopharyngeal cancer.</span>
          }
        ]}/>
      </GlobalWrapper>
    </Layout>
  );
}
